import { isEqual } from 'lodash'

const areQueriesEqual = (query1, query2) => {
  // Normalize queries to avoid inconsistencies
  const normalizeQuery = (query) =>
    Object.keys(query).reduce((acc, key) => {
      acc[key] = Array.isArray(query[key]) ? query[key] : [query[key]];
      return acc;
    }, {});

  // Deep compare
  return isEqual(normalizeQuery(query1), normalizeQuery(query2));
}

export default areQueriesEqual
