/**
 * Sorts the configurable_options and configurable_children of a product.
 * @param {Object} product - The product object to sort.
 * @returns {Object} - The sorted product object.
 */
export function sortProductData (product) {
  if (!product) return product;
  const sortedProduct = { ...product };
  // 1. Sort configurable_options.values alphabetically based on label
  if (sortedProduct.configurable_options && Array.isArray(sortedProduct.configurable_options)) {
    sortedProduct.configurable_options = sortedProduct.configurable_options.map(option => {
      if (option.values && Array.isArray(option.values)) {
        return {
          ...option,
          values: [...option.values].sort((a, b) => a.label.localeCompare(b.label)),
        };
      }
      return option;
    });
  }

  // 2. Create a sorting priority map based on sorted configurable_options
  const attributeSortMap = {};
  if (sortedProduct.configurable_options && Array.isArray(sortedProduct.configurable_options)) {
    sortedProduct.configurable_options.forEach(option => {
      if (option.values && Array.isArray(option.values)) {
        option.values.forEach(value => {
          attributeSortMap[value.value_index] = value.label;
        });
      }
    });
  }

  // 3. Sort configurable_children based on the sorted order of their attribute labels
  if (sortedProduct.configurable_children && Array.isArray(sortedProduct.configurable_children)) {
    try {
      sortedProduct.configurable_children = [...sortedProduct.configurable_children].sort((a, b) => {
        for (let option of sortedProduct.configurable_options) {
          const attrCode = option.attribute_code;
          const aValue = a[attrCode] ? a[attrCode].toString() : '';
          const bValue = b[attrCode] ? b[attrCode].toString() : '';
          const aLabel = attributeSortMap[a[attrCode]] || aValue;
          const bLabel = attributeSortMap[b[attrCode]] || bValue;
          const comparison = aLabel.localeCompare(bLabel);
          if (comparison !== 0) {
            return comparison;
          }
        }
        return 0; // All compared attributes are equal
      });
    } catch (error) {
      console.error('Error while sorting product data', error);
    }
  }

  return sortedProduct;
}
