import config from 'config'
import FilterVariant from 'core/modules/catalog-next/types/FilterVariant'
import { Filters } from '../types/Category'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

import {
  AVAILABILITY_TYPE,
  DATE_FROM,
  DATE_TO, PARTNER_TYPE,
  SEARCH_STRING_TYPE
} from '../../../../src/constants/FilterVariantTypes';
// import { delete } from 'vue/types/umd';

export const getSystemFilterNames: string[] = config.products.systemFilterNames;

export const getQueryFromFilterVariants = (filterVariants: FilterVariant[] = []) => {
  const query = {};
  filterVariants.forEach(filter => {
    if (filter.type === AVAILABILITY_TYPE && isNotEmptyString(filter.from) && isNotEmptyString(filter.to)) {
      query[DATE_FROM] = [filter.from];
      query[DATE_TO] = [filter.to];
    } else if (filter.type === SEARCH_STRING_TYPE && isNotEmptyString(filter.value)) {
      query[SEARCH_STRING_TYPE] = [filter.value];
    } else {
      if (filter.id) {
        query[filter.type] = query[filter.type] ? [...query[filter.type], filter.id] : [filter.id];
      }
    }
  });
  return query;
};

/**
 * Creates new filtersQuery (based on currentQuery) by modifying specific filter variant.
 */
export const changeFilterQuery = ({currentQuery = {}, filterVariant}: {currentQuery?: any, filterVariant?: FilterVariant} = {}) => {
  const newQuery = JSON.parse(JSON.stringify(currentQuery))

  if (!filterVariant) {
    return newQuery
  }
  const variantId = filterVariant.id.toString();

  if (filterVariant.type === AVAILABILITY_TYPE) {
    if (filterVariant.from === '' || filterVariant.to === '' || !filterVariant.to || !filterVariant.from) {
      // console.log('availability from has wrong or no values %c ' + filterVariant.from , 'color:red')
      delete newQuery[filterVariant.type];

      // delete old dates from search
      if (newQuery[DATE_FROM]) {
        delete newQuery.dateFrom;
      }
      if (newQuery[DATE_TO]) {
        delete newQuery.dateTo
      }
    } else {
      const filterFrom = [filterVariant.from]
      const filterTo = [filterVariant.to]
      newQuery[DATE_FROM] = filterFrom
      newQuery[DATE_TO] = filterTo
    }
  } else if (filterVariant.type === SEARCH_STRING_TYPE) {
    if (filterVariant.value === '' || filterVariant.value === null) {
      // console.log('availability from has wrong or no values %c ' + filterVariant.from , 'color:red')
      delete newQuery[filterVariant.type]
    } else {
      const searchString = [filterVariant.value]
      newQuery[SEARCH_STRING_TYPE] = searchString
    }
  } else {
    // system filter names dont matter (sort.. etc)
    if (getSystemFilterNames.includes(filterVariant.type)) {
      if (newQuery[filterVariant.type] && newQuery[filterVariant.type] === variantId) {
        delete newQuery[filterVariant.type]
      } else {
        newQuery[filterVariant.type] = variantId;
      }
    } else {
      let queryFilter = newQuery[filterVariant.type] || []
      if (!Array.isArray(queryFilter)) {
        queryFilter = [queryFilter]
      }
      if (queryFilter.includes(variantId)) {
        queryFilter = queryFilter.filter(value => value.toString() !== variantId);
      } else if (filterVariant.single) {
        queryFilter = [variantId];
      } else {
        queryFilter.push(variantId);
      }
      // delete or add filter variant to query
      // if (filterVariant.type === "availability"){
      if (!queryFilter.length) {
        // console.log('delete filtervar type')
        delete newQuery[filterVariant.type]
      } else {
        newQuery[filterVariant.type] = queryFilter
      }
    }
  }
  return newQuery
}

export const getFiltersFromQuery = ({ filtersQuery = {}, availableFilters = {} } = {}, rootGetters) : { filters: Filters } => {
  const queryInfo = getFiltersFromQueryInfo({ availableFilters, filtersQuery }, rootGetters);
  return queryInfo.searchQuery;
};

/**
 * Returns information about applied query filters. Whether their type and value aligns with available filters,
 * which filters are applicable and which not, also If whole query can be applied in order to get any results.
 * @param filtersQuery
 * @param availableFilters
 * @param rootGetters
 */
export const getFiltersFromQueryInfo = ({ filtersQuery = {}, availableFilters = {} } = {}, rootGetters)
: { searchQuery: { filters: Filters }, nonApplicableFilters: Object, isQueryApplicable } => {
  const queryInfo = {
    searchQuery: {
      filters: {}
    },
    nonApplicableFilters: {},
    isQueryApplicable: false
  };

  Object.keys(filtersQuery).forEach(filterKey => {
    const filter = availableFilters[filterKey];
    const queryValue = filtersQuery[filterKey];

    if (!filter) {
      return;
    }
    if (getSystemFilterNames.includes(filterKey)) {
      queryInfo.searchQuery[filterKey] = queryValue;
    } else if (filterKey === SEARCH_STRING_TYPE) {
      addSearchStringFilter(queryValue, queryInfo);
    } else if (filterKey === DATE_TO) {
      addAvailabilityFilter(filtersQuery, queryInfo);
    } else if (filterKey === PARTNER_TYPE) {
      const partners = rootGetters['partner/getPartners'];
      addPartnerFilters(partners, queryValue, queryInfo);
    } else if (![DATE_FROM].includes(filterKey)) {
      addFilter(filtersQuery, filterKey, filter, queryInfo);
    }
  });
  return queryInfo;
};

const addSearchStringFilter = (queryValue, queryInfo) => {
  const searchQueryFilter = {
    attribute_code: SEARCH_STRING_TYPE,
    single: true,
    type: SEARCH_STRING_TYPE,
    label: 'shalom',
    value: queryValue
  };
  queryInfo.searchQuery.filters[SEARCH_STRING_TYPE] = searchQueryFilter;
};

const addAvailabilityFilter = (filtersQuery, queryInfo) => {
  if (!filtersQuery[DATE_FROM] || !filtersQuery[DATE_TO]) {
    return
  }
  // 2020-10-23T16:59:00.000Z = converting to elastic format
  let dateFrom = typeof filtersQuery[DATE_FROM] === 'string' ? filtersQuery[DATE_FROM] : filtersQuery[DATE_FROM][0];
  let dateTo = typeof filtersQuery[DATE_TO] === 'string' ? filtersQuery[DATE_TO] : filtersQuery[DATE_TO][0];
  dateFrom = dayjs(dateFrom?.replace(' ', '+')).utc().toISOString();
  dateTo = dayjs(dateTo?.replace(' ', '+')).utc().toISOString();

  const availabilityFilter = {
    attribute_code: AVAILABILITY_TYPE,
    from: dateFrom,
    single: true,
    to: dateTo,
    type: AVAILABILITY_TYPE
  };
  queryInfo.searchQuery.filters[AVAILABILITY_TYPE] = availabilityFilter;
};

const addPartnerFilters = (partners, queryValue, queryInfo) => {
  const modifiedPartners = partners.map(partner => ({ ...partner, id: partner.id.toString() }));
  const partnerIds = !Array.isArray(queryValue) ? [queryValue.toString()] : queryValue.map(qv => qv.toString());
  const filteredPartners = modifiedPartners.filter(partner => !!partnerIds.find(id => id === partner.id));
  const unfilteredPartnerIds = partnerIds.filter(id => !modifiedPartners.find(p => p.id === id));

  if (unfilteredPartnerIds.length) {
    queryInfo.nonApplicableFilters[PARTNER_TYPE] = unfilteredPartnerIds;
    queryInfo.isQueryApplicable = queryInfo.isQueryApplicable || !filteredPartners.length;
  }

  if (filteredPartners.length) {
    queryInfo.searchQuery.filters[PARTNER_TYPE] = [];

    filteredPartners.forEach(partner =>
      queryInfo.searchQuery.filters[PARTNER_TYPE].push({
        id: partner.id,
        label: partner.name,
        type: PARTNER_TYPE,
        attribute_code: PARTNER_TYPE
      })
    );
  }
  filteredPartners.sort((a, b) => a.name.localeCompare(b.name, 'cs', {sensitivity: 'base'}))
};

const addFilter = (filtersQuery, filterKey, filter, queryInfo) => {
  const queryValues = [].concat(filtersQuery[filterKey]);
  const filteredAvailableFilters = filter.filter(f => queryValues.find(value => value.toString() === f.id.toString()));
  const unfilteredQueryValues = queryValues.filter(value => !filter.find(f => f.id.toString() === value.toString()));

  if (unfilteredQueryValues.length) {
    queryInfo.nonApplicableFilters[PARTNER_TYPE] = unfilteredQueryValues;
    queryInfo.isQueryApplicable = queryInfo.isQueryApplicable || !filteredAvailableFilters.length;
  }
  if (filteredAvailableFilters.length) {
    queryInfo.searchQuery.filters[filterKey] = [];

    filteredAvailableFilters.forEach(filter =>
      queryInfo.searchQuery.filters[filterKey].push({ ...filter, attribute_code: filterKey }));
  }
};

const isNotEmptyString = (string: string): boolean => {
  return string && string !== ''
}
